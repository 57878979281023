<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('tags.tags') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <template v-if="tags.length > 0">
            <b-list-group>
              <b-list-group-item v-for="t in tags" :key="t.id" :class="'d-flex align-items-center enabled-' + t.published"
                  :to="{ name : 'EditTag', params: { tagId: t.id }}">
                <b-avatar variant="primary" class="mr-4"><BIconTag scale="1.2" /></b-avatar>
                <template v-if="t.names.length > 0">
                  <span v-for="name in t.names" :key="name.id">
                    <template v-if="$root.lang.shortName == name.language.shortName">
                      <h4>{{ name.name }}</h4>
                    </template>
                  </span>
                </template>
              </b-list-group-item>
            </b-list-group>
            </template>
            <template v-else>
              <h3>{{$t('tags.no-published-tags')}}</h3>
            </template>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import NavBar from "@/components/common/NavBar.vue"
import { BAvatar, BIconTag } from 'bootstrap-vue'
export default {
  name: 'AllTags',
  components: { NavBar, BAvatar, BIconTag },
  data: function() {
    return {
      tags: [],
      user: this.$root.user,
      showOverlay: true
    }
  },
  created: async function() {
    await this.$http.get("/cms/api/tag/all-published/", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.tags = r.data;
      })
      .catch(e => { this.handleError(e); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
